import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import axiosDataPipeline from "../api/axiosDataPipeline";
import { useAuth } from '../auth/AuthContext';
import { formatDate, normalizeDate } from '../utils/dataProcessing';
import {
    initializeDataTypeStructure,
} from '../utils/dataContextUtils';
import {
    CACHE_EXPIRATION,
    getIdParam,
    additionalEndpoints,
} from '../config/dataContextConfig';
import { useYearOverYear } from '../hooks/dataContext/useYearOverYear';
import { usePreviousPeriod } from '../hooks/dataContext/usePreviousPeriod';
import { useCustomPeriodData } from '../hooks/dataContext/useCustomPeriodData';
import { useKpiData } from '../hooks/dataContext/useKpiData';
import { dataTypes } from '../config/dataTypes';

/**
 * Context for managing global application data state
 */
const GlobalDataContext = createContext();

export const GlobalDataProvider = ({ children }) => {
    const { token, loading: authLoading } = useAuth();
    const location = useLocation();
    const { id } = useParams();

    // Primary data states
    /**
     * Main data store containing current, year-over-year, and previous period data
     */
    const [globalData, setGlobalData] = useState(initializeDataTypeStructure(dataTypes));

    /**
     * Manage comparison states for when dates change.
     */

    // Track the previous account ID to detect changes and reset settings.
    const prevIdRef = useRef(id);

    const [comparativeDataActive, setComparativeDataActive] = useState({
        previousPeriod: false,
        yearOverYear: false,
        customPeriod: false
    });

    // Reset states when ID changes
    useEffect(() => {
        if (id !== prevIdRef.current) {
            setComparativeDataActive({
                previousPeriod: false,
                yearOverYear: false,
                customPeriod: false
            });
            prevIdRef.current = id;
        }
    }, [id]);

    const loadPreviousDataRef = useRef(null);
    const loadYearOverYearDataRef = useRef(null);
    const loadCustomPeriodDataRef = useRef(null);

    // Status and metadata states
    /**
     * Tracks loading state for each data type and comparison period
     */
    const [loadingStates, setLoadingStates] = useState({
        ...Object.fromEntries(dataTypes.map(type => [type.key, false])),
        yearOverYear: false,
        previousPeriod: false,
        customPeriod: false
    });
    /**
     * Tracks which data sources have valid data
     */
    const [sourcesWithData, setSourcesWithData] = useState({});
    /**
     * Stores error states for each data type
     */
    const [errorStates, setErrorStates] = useState({});
    /**
     * Stores available accounts for each data type
     */
    const [accounts, setAccounts] = useState(null);

    // Date range states
    /**
     * Current period date range
     */
    const [currentStartDate, setCurrentStartDate] = useState(null);
    const [currentEndDate, setCurrentEndDate] = useState(null);
    /**
     * Previous period date range
     */
    const [previousStartDate, setPreviousStartDate] = useState(null);
    const [previousEndDate, setPreviousEndDate] = useState(null);

    const [customStartDate, setCustomStartDate] = useState(null);
    const [customEndDate, setCustomEndDate] = useState(null);

    const [kpiData, setKpiData] = useState({});
    const [isKpiDataLoaded, setIsKpiDataLoaded] = useState(false);

    // Loading status flags
    /**
     * Tracks whether previous period data has been loaded
     */
    const [isPreviousDataLoaded, setIsPreviousDataLoaded] = useState(false);
    /**
     * Tracks whether year-over-year data has been loaded
     */
    const [isYearOverYearDataLoaded, setIsYearOverYearDataLoaded] = useState(false);
    /**
     * Tracks whether custom period data has been loaded
     */
    const [isCustomPeriodDataLoaded, setIsCustomPeriodDataLoaded] = useState(false);

    // Refs for caching and request management
    /**
     * Cache for API responses to prevent duplicate requests
     */
    const dataCache = useRef({});

    /**
     * Tracks pending API requests to prevent duplicates
     */
    const pendingRequests = useRef({});
    const [isDataLoadingComplete, setIsDataLoadingComplete] = useState(false);

    /**
     * Fetches account information for all data types
     * @param {string} token - Authentication token
     * @param {string} [adminId] - Optional admin ID for filtered results
     */
    const fetchAccounts = useCallback(async (adminId = null) => {
        if (!token || authLoading) return; // Don't fetch if no token or auth is loading

        try {
            let apiPath = '/user/accounts';
            if (adminId) {
                apiPath += `?id=${adminId}`;
            }
            const accountsResponse = await axiosDataPipeline.get(apiPath);
            accountsResponse.data['ga4-organic'] = accountsResponse.data.ga4;
            accountsResponse.data['ecommerce'] = accountsResponse.data.ga4;
            setAccounts(accountsResponse.data);
        } catch (error) {
            console.error('Error fetching accounts:', error);
        }
    }, [token, authLoading]);

    useEffect(() => {
        if (token) {
            axiosDataPipeline.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        } else {
            delete axiosDataPipeline.defaults.headers.common['Authorization'];
        }
    }, [token]);

    /**
     * Makes API calls with caching and duplicate request prevention
     * @param {string} url - API endpoint URL
     * @param {Object} params - Request parameters
     * @returns {Promise<Object>} API response data
     */
    const makeApiCall = useCallback(async (url, params) => {
        const cacheKey = `${url}-${JSON.stringify(params)}`;
        const now = Date.now();

        if (dataCache.current[cacheKey] && now - dataCache.current[cacheKey].timestamp < CACHE_EXPIRATION) {
            return dataCache.current[cacheKey].data;
        }

        if (pendingRequests.current[cacheKey]) {
            return pendingRequests.current[cacheKey];
        }

        pendingRequests.current[cacheKey] = axiosDataPipeline.post(url, params)
            .then(response => {
                dataCache.current[cacheKey] = { data: response.data, timestamp: now };
                delete pendingRequests.current[cacheKey];
                return response.data;
            })
            .catch(error => {
                delete pendingRequests.current[cacheKey];
                throw error;
            });

        return pendingRequests.current[cacheKey];
    }, []);

    /**
     * Fetches data for a specific type and period
     * @param {string} key - Data type key
     * @param {string} id - Account ID
     * @param {Object} currentPeriod - Current period date range
     * @param {Object} yearOverYearPeriod - Year-over-year period date range
     * @param {Object} previousPeriod - Previous period date range
     * @param {string} endpoint - Additional endpoint for specific metrics
     * @param {boolean} includeYearOverYear - Whether to include year-over-year data
     * @param {boolean} includePreviousPeriod - Whether to include previous period data
     * @returns {Promise<Object>} Fetched data for all requested periods
     */
    const fetchDataForType = useCallback(async (
        key,
        id,
        currentPeriod,
        yearOverYearPeriod,
        previousPeriod,
        endpoint = '',
        includeYearOverYear = false,
        includePreviousPeriod = false,
        forceOrganic = false
    ) => {
        try {
            const result = {
                current: null,
                yearOverYear: null,
                previousPeriod: null
            };

            let commonParams = {
                ...getIdParam(key, id)
            };

            // Split GSC into two requests
            if (key === 'gsc') {
                // Function to make both types of GSC requests
                const fetchGSCData = async (dateRange) => {
                    // Request for totals (with date dimension)
                    const totalsParams = {
                        ...commonParams,
                        ...dateRange,
                        rowLimit: 25000,
                        searchType: 'web',
                        dimensions: ['date']
                    };

                    // Request for keywords (without date dimension)
                    const keywordsParams = {
                        ...commonParams,
                        ...dateRange,
                        rowLimit: 25000,
                        searchType: 'web',
                        dimensions: ['date', 'query']
                    };

                    const pagesParams = {
                        ...commonParams,
                        ...dateRange,
                        rowLimit: 25000,
                        searchType: 'web',
                        dimensions: ['date', 'page']
                    };

                    const [totalsResponse, keywordsResponse, pagesResponse] = await Promise.all([
                        makeApiCall('/gsc/performance', totalsParams),
                        makeApiCall('/gsc/performance', keywordsParams),
                        makeApiCall('/gsc/performance', pagesParams)
                    ]);

                    return {
                        totals: totalsResponse,
                        keywords: keywordsResponse,
                        pages: pagesResponse
                    };
                };

                // Fetch data for current period
                if (currentPeriod) {
                    const currentData = await fetchGSCData({ dateRanges: currentPeriod });
                    result.current = {
                        ...currentData.totals,
                        keywords: currentData.keywords,
                        pages: currentData.pages,
                    };
                }

                // Fetch year-over-year data if requested
                if (includeYearOverYear && yearOverYearPeriod) {
                    const yearOverYearData = await fetchGSCData({ dateRanges: yearOverYearPeriod });
                    result.yearOverYear = {
                        ...yearOverYearData.totals,
                        keywords: yearOverYearData.keywords,
                        pages: yearOverYearData.pages
                    };
                }

                // Fetch previous period data if requested
                if (includePreviousPeriod && previousPeriod) {
                    const previousData = await fetchGSCData({ dateRanges: previousPeriod });
                    result.previousPeriod = {
                        ...previousData.totals,
                        keywords: previousData.keywords,
                        pages: previousData.pages
                    };
                }

                return result;
            }

            if (key === 'ga4-organic' || forceOrganic) {
                commonParams.dimensionFilter = {
                    filter: {
                        fieldName: "sessionPrimaryChannelGroup",
                        stringFilter: {
                            matchType: "EXACT",
                            value: "Organic Search"
                        }
                    }
                };
            }

            if (endpoint) {
                switch (endpoint) {
                    case '/bouncerate':
                        commonParams.metrics = [
                            { name: "bounceRate" },
                            { name: "sessions" }
                        ];
                        commonParams.dimensions = [{ name: "date" }];
                        endpoint = '';
                        break;
                    case '/new':
                        commonParams.metrics = [{ name: "totalUsers" }, { name: "sessions" }];
                        commonParams.dimensions = [{ name: "date" }, { name: "newVsReturning" }];
                        endpoint = '';
                        break;
                    case '/active':
                        commonParams.metrics = [{ name: "activeUsers" }];
                        commonParams.dimensions = [];
                        endpoint = '';
                        break;
                    case '/devices':
                        commonParams.metrics = [{ name: "sessions" }];
                        commonParams.dimensions = [{ name: "date" }, { name: "deviceCategory" }];
                        endpoint = '';
                        break;
                    case '/pages':
                        commonParams.metrics = [{ name: "screenPageViewsPerSession" }];
                        commonParams.dimensions = [{ name: "date" }];
                        endpoint = '';
                        break;
                    case '/sessions':
                        commonParams.metrics = [{ name: "sessions" }, { name: "totalUsers" }];
                        commonParams.dimensions = [{ name: "date" }];
                        endpoint = '';
                        break;
                    case '/sources':
                        commonParams.metrics = [{ name: "sessions" }];
                        commonParams.dimensions = [
                            { name: "date" }, 
                            { name: "sessionPrimaryChannelGroup" }
                        ];                        
                        endpoint = '';
                        break;
                    case '/averageduration':
                        commonParams.metrics = [
                            { name: "averageSessionDuration" },
                            { name: "sessions" }
                        ];
                        commonParams.dimensions = [{ name: "date" }];
                        endpoint = '';
                        break;
                }
            }

            const apiEndpoint = key === 'ga4-organic' || key === 'ecommerce' ? '/ga4/performance' : `/${key}/performance`;

            // if (endpoint) {
            //     console.log('endpoint: ' + endpoint);
            //     console.log('api endpoint: ' + apiEndpoint);
            // }

            if (currentPeriod) {
                const currentParams = {
                    ...commonParams,
                    dateRanges: currentPeriod
                };
                result.current = await makeApiCall(
                    `${apiEndpoint}${endpoint}`,
                    currentParams
                ).catch(error => {
                    console.error(`Error fetching current data for ${key}:`, error);
                    return null;
                });
            }

            if (includeYearOverYear && yearOverYearPeriod) {
                const yearOverYearParams = {
                    ...commonParams,
                    dateRanges: yearOverYearPeriod
                };
                result.yearOverYear = await makeApiCall(
                    `${apiEndpoint}${endpoint}`,
                    yearOverYearParams
                ).catch(error => {
                    console.error(`Error fetching year-over-year data for ${key}:`, error);
                    return null;
                });
            }

            if (includePreviousPeriod && previousPeriod) {
                const previousParams = {
                    ...commonParams,
                    dateRanges: previousPeriod
                };
                result.previousPeriod = await makeApiCall(
                    `/${key}/performance${endpoint}`,
                    previousParams
                ).catch(error => {
                    console.error(`Error fetching previous period data for ${key}:`, error);
                    return null;
                });
            }

            return result;

        } catch (error) {
            console.error(`Error in fetchDataForType for ${key}:`, error);
            return {
                current: null,
                yearOverYear: null,
                previousPeriod: null
            };
        }
    }, [makeApiCall]);

    const { loadPreviousData } = usePreviousPeriod({
        accounts,
        currentStartDate,
        currentEndDate,
        fetchDataForType,
        setLoadingStates,
        setGlobalData,
        setIsPreviousDataLoaded,
        setPreviousStartDate,
        setPreviousEndDate,
        dataTypes
    });

    const { loadYearOverYearData } = useYearOverYear({
        accounts,
        currentStartDate,
        currentEndDate,
        fetchDataForType,
        setLoadingStates,
        setGlobalData,
        setIsYearOverYearDataLoaded,
        dataTypes
    });

    const { loadCustomPeriodData } = useCustomPeriodData({
        accounts,
        currentStartDate,
        currentEndDate,
        fetchDataForType,
        setLoadingStates,
        setGlobalData,
        setIsCustomPeriodDataLoaded,
        setCustomStartDate,
        setCustomEndDate,
        dataTypes
    });

    loadPreviousDataRef.current = loadPreviousData;
    loadYearOverYearDataRef.current = loadYearOverYearData;
    loadCustomPeriodDataRef.current = loadCustomPeriodData;

    /**
     * Main data fetching function that coordinates fetching all data types
     * @param {Date} startDate - Period start date
     * @param {Date} endDate - Period end date
     */
    const fetchData = useCallback(async (startDate, endDate) => {
        const normalizedStart = normalizeDate(startDate);
        const normalizedEnd = normalizeDate(endDate);

        setCurrentStartDate(normalizedStart);
        setCurrentEndDate(normalizedEnd);

        // Initial setup
        setLoadingStates(prev => ({
            ...prev,
            ...Object.fromEntries(dataTypes.map(type => [type.key, true]))
        }));
        setIsDataLoadingComplete(false);

        setIsYearOverYearDataLoaded(false);
        setIsPreviousDataLoaded(false);

        const newData = {
            current: {},
            yearOverYear: {},
            previousPeriod: {}
        };

        setSourcesWithData(prev => Object.fromEntries(dataTypes.map(type => [type.key, false])));
        setErrorStates(prev => Object.fromEntries(dataTypes.map(type => [type.key, {}])));

        const currentPeriod = {
            startDate: formatDate(normalizedStart),
            endDate: formatDate(normalizedEnd)
        };

        // Prepare all promises in parallel
        const allPromises = dataTypes.flatMap(({ key }) => {
            if (!accounts?.[key] || key === 'reviews') return [];

            newData.current[key] = {};
            const newErrors = {};

            return accounts[key].map(async (id) => {
                try {
                    // Base data fetch
                    const result = await fetchDataForType(
                        key,
                        id,
                        currentPeriod,
                        null,
                        null,
                        '',
                        false,
                        false,
                    );

                    if (result.current) {
                        newData.current[key][id] = result.current;
                    }

                    // GA4 specific endpoints
                    if ((key === 'ga4' || key === 'ga4-organic') && result.current) {
                        const endpointPromises = additionalEndpoints.map(async (endpoint) => {
                            const endpointResult = await fetchDataForType(
                                key,
                                id,
                                currentPeriod,
                                null,
                                null,
                                endpoint,
                                false,
                                false,
                                key === 'ga4-organic'
                            );
                            return { endpoint: endpoint.slice(1), ...endpointResult };
                        });

                        const endpointResults = await Promise.all(endpointPromises);
                        endpointResults.forEach(({ endpoint, current }) => {
                            if (current) {
                                newData.current[key][id] = {
                                    ...newData.current[key][id],
                                    [endpoint]: current
                                };
                            }
                        });
                    }

                    // Ecommerce specific logic
                    if (key === 'ecommerce') {
                        const [overview, items, categories] = await Promise.all([
                            fetchDataForType('ga4', id, currentPeriod, null, null, '/ecommerce', false, false),
                            fetchDataForType('ga4', id, currentPeriod, null, null, '/ecommerce/items', false, false),
                            fetchDataForType('ga4', id, currentPeriod, null, null, '/ecommerce/categories', false, false)
                        ]);

                        newData.current[key][id] = {
                            overview: overview.current,
                            items: items.current,
                            categories: categories.current
                        };
                    }
                } catch (error) {
                    console.error(`Error fetching data for ${key}:`, error);
                    newErrors[id] = error.message || 'An error occurred';
                }
            });
        });

        // Reviews handling in parallel
        if (accounts?.gbp) {
            const reviewsPromises = accounts.gbp.map(async (mapsId) => {
                try {
                    const response = await axiosDataPipeline.post('/gbp/reviews', {
                        mapsId,
                        pageSize: 500
                    });
                    newData.current.reviews = newData.current.reviews || {};
                    newData.current.reviews[mapsId] = response.data;
                } catch (error) {
                    console.error('Error fetching reviews for mapsId:', mapsId, error);
                    return { mapsId, error: error.message || 'An error occurred' };
                }
            });

            allPromises.push(...reviewsPromises);
        }

        // Execute all promises in parallel
        await Promise.all(allPromises);

        // Update data validation
        const dataValidation = {};
        for (const { key } of dataTypes) {
            if (key === 'ga4-organic') {
                dataValidation[key] = Boolean(
                    newData.current['ga4'] &&
                    Object.keys(newData.current['ga4']).length > 0
                );
            } else if (key === 'reviews') {
                dataValidation[key] = Boolean(
                    newData.current[key] &&
                    Object.keys(newData.current[key]).length > 0
                );
            } else if (key === 'ecommerce') {
                dataValidation[key] = Boolean(
                    newData.current[key] &&
                    Object.keys(newData.current[key]).length > 0 &&
                    Object.values(newData.current[key]).some(value =>
                        value?.overview && Object.keys(value.overview).length > 0
                    )
                );
            } else {
                dataValidation[key] = Boolean(
                    newData.current[key] &&
                    Object.keys(newData.current[key]).length > 0
                );
            }
        }

        setSourcesWithData(dataValidation);

        if (comparativeDataActive.previousPeriod && loadPreviousDataRef.current) {
            await loadPreviousDataRef.current();
        }
        if (comparativeDataActive.yearOverYear && loadYearOverYearDataRef.current) {
            await loadYearOverYearDataRef.current();
        }
        if (comparativeDataActive.customPeriod && loadCustomPeriodDataRef.current) {
            await loadCustomPeriodDataRef.current();
        }

        // Update final states
        setGlobalData(prev => ({
            current: newData.current,
            yearOverYear: prev.yearOverYear,
            previousPeriod: prev.previousPeriod,
            customPeriod: prev.customPeriod
        }));

        setLoadingStates(prev => ({
            ...prev,
            ...Object.fromEntries(dataTypes.map(type => [type.key, false]))
        }));

        setIsDataLoadingComplete(true);
    }, [accounts, fetchDataForType, comparativeDataActive]);

    const {
        loadKpiData
    } = useKpiData({
        accounts,
        fetchDataForType,
        setLoadingStates,
        setKpiData,
        setIsKpiDataLoaded,
        dataTypes
    });

    return (
        <GlobalDataContext.Provider
            value={{
                globalData,
                loadingStates,
                sourcesWithData,
                errorStates,
                accounts,
                previousStartDate,
                previousEndDate,
                currentStartDate,
                currentEndDate,
                fetchAccounts,
                fetchData,
                loadPreviousData,
                loadYearOverYearData,
                loadCustomPeriodData,
                isYearOverYearDataLoaded,
                isPreviousDataLoaded,
                isDataLoadingComplete,
                customStartDate,
                customEndDate,
                isCustomPeriodDataLoaded,
                kpiData,
                isKpiDataLoaded,
                loadKpiData,
                setComparativeDataActive,
                comparativeDataActive
            }}
        >
            {children}
        </GlobalDataContext.Provider>
    );
};

/**
 * Custom hook to access the global data context
 * @returns {Object} Global data context value
 */
export const useGlobalData = () => useContext(GlobalDataContext);
