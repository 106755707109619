// import { DocumentArrowUpIcon, CurrencyDollarIcon } from '@heroicons/react/24/solid';

export const navSectionsConfig = [
    {
        id: 'dashboard-top',
        name: 'Top',
        // iconComponent: DocumentArrowUpIcon,
        iconClasses: "dropdown-option-icon text-blue-600",
        key: 'top',
        apiKey: null,
        showWithEcommerce: true
    },
    {
        id: 'dashboard-notes',
        name: 'Summary',
        iconClasses: "dropdown-option-icon text-blue-600",
        key: 'summary',
        apiKey: null,
        showWithEcommerce: false
    },
    {
        id: 'dashboard-snapshot',
        name: 'Account Snapshot',
        icon: '/snapshot_icon.png',
        key: 'snapshot',
        apiKey: null,
        showWithEcommerce: false
    },
    {
        id: 'dashboard-leadgen',
        name: 'Contact Sources',
        icon: '/lead_gen_icon.png',
        key: 'leadgen',
        apiKey: null,
        showWithEcommerce: false
    },
    {
        id: 'dashboard-ecommerce',
        name: 'E-Commerce',
        // iconComponent: CurrencyDollarIcon,
        iconClasses: "dropdown-option-icon text-green-600",
        key: 'ecommerce',
        apiKey: 'ecommerce',
        showWithEcommerce: true
    },
    {
        id: 'dashboard-traffic',
        name: 'Visibility & Traffic',
        icon: '/ga_icon.svg',
        key: 'ga4',
        apiKey: 'ga4',
        showWithEcommerce: true
    },
    {
        id: 'dashboard-organic',
        name: 'Organic Traffic',
        icon: '/organic-icon.png',
        key: 'ga4-organic',
        apiKey: 'ga4-organic',
        showWithEcommerce: true
    },
    {
        id: 'dashboard-gsc',
        name: 'Google Search Console',
        icon: '/gsc_icon.svg',
        key: 'gsc',
        apiKey: 'gsc',
        showWithEcommerce: true
    },
    {
        id: 'dashboard-gbp',
        name: 'Google Business Profile',
        icon: '/gbp_icon.svg',
        key: 'gbp',
        apiKey: 'gbp',
        showWithEcommerce: true
    },
    {
        id: 'dashboard-calls',
        name: 'Call Tracking',
        icon: '/phone_icon.svg',
        key: 'call-tracking',
        apiKey: 'call-tracking',
        showWithEcommerce: true
    },
    {
        id: 'dashboard-reviews',
        name: 'Reviews',
        icon: '/reviews_icon.svg',
        key: 'reviews',
        apiKey: 'reviews',
        showWithEcommerce: true
    }
];

export const getAvailableSections = (sourcesWithData, requireNavigationProps = true) => {
    const hasEcommerceData = sourcesWithData?.ecommerce;
    
    return navSectionsConfig.filter(section => {
        // Skip navigation-specific props if not needed
        if (!requireNavigationProps && section.key === 'top') {
            return false;
        }

        // Always include summary section when not requiring navigation props
        if (!requireNavigationProps && section.key === 'summary') {
            return true;
        }

        // Handle non-API sections
        if (!section.apiKey) {
            return hasEcommerceData ? section.showWithEcommerce : true;
        }

        // Handle API-dependent sections
        return sourcesWithData[section.apiKey];
    });
};
