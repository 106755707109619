import { DocumentArrowUpIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline';

const StatcardHeader = ({
    title,
    icon,
    accountName,
    isExpanded,
    toggleExpand,
    startDate,
    endDate,
}) => {
    let dataSource;

    switch (title) {
        case 'Contact Sources':
            dataSource = 'Google Analytics 4';
            break;
        case 'Visibility & Traffic':
            dataSource = 'Google Analytics 4';
            break;
        case 'Organic Traffic':
            dataSource = 'Google Analytics 4 (Organic Only)';
            break;
        case 'Google Search Console':
            dataSource = 'Google Search Console';
            break;
        case 'Google Business Profile':
            dataSource = 'Google Business Profile';
            break;
        // Add more cases as needed
        default:
            dataSource = ''; // Empty string if no match
    }

    return (
        <div
            className='rr-statcard-header'
        >
            <div className='rr-statcard-title'>
                {icon && (
                    <div className='rr-statcard-title-icon'>
                        <img src={icon} alt={title} />
                    </div>
                )}
                <div
                    className='rr-statcard-title-account'
                >
                    <h1>{title}</h1>
                    <div className='rr-statcard-subtitle'>
                        {accountName && (
                            <h4 className='rr-statcard-title-accountname'>{accountName}</h4>
                        )}
                        {startDate && endDate && (
                            <>
                                <span className='mx-3 hidden lg:flex white'>&bull;</span>
                                <h4 className='rr-statcard-title-date'>{startDate} - {endDate}</h4>
                            </>
                        )}
                    </div>
                    {dataSource && (
                        <div className='rr-statcard-source'>
                            <span className='source-title'>Data source:</span>
                            <span className='source-name'>{dataSource}</span>
                        </div>
                    )}
                </div>
            </div>
            <div className='rr-statcard-right'>
                <button onClick={toggleExpand} className='statcard-header-toggle'>
                    {isExpanded ? (
                        <ChevronUpIcon className='h-5 w-5 lg:h-7 lg:w-7' aria-hidden='true' />
                    ) : (
                        <ChevronDownIcon className='h-5 w-5 lg:h-7 lg:w-7' aria-hidden='true' />
                    )}
                </button>
            </div>
        </div>
    );
};

export default StatcardHeader;
